// Util
import { SortUtil } from '@/utils/sortUtil';
import { LocationUtil } from '@/utils/locationUtil';

// Others
import config from '@/config/env-constants';
const moment = require('moment-timezone');
import _ from 'lodash';


function getCompanyDisplay(company) {
	if (company && !_.isEmpty(company)) {
		let status = company.isActive === 'true' ? 'active' : 'inactive';
		return company.name + " (" + status + ")";
	}
	return "";
}

function getStorageLocationDisplay(storageLocation) {
	if (!_.isEmpty(storageLocation)) {
		let status = storageLocation.isActive === 'true' ? 'active' : 'inactive';
		return storageLocation.name + " (" + status + ")";
	}
	return "";
}

function getCompanyItem(company) {
	return {
		id: company.id ? company.id : null,
		name: company.name,
		isActive: company.isActive,
		type: company.type,
		brand: company.brand,
		contactNo: company.contactNo
	}
}

function getStorageLocationItem(loc) {
	return {
		id: loc.id,
		name: loc.name,
		isActive: loc.isActive,
		geoaddress: LocationUtil.getGeoaddress(
			loc.geoaddress
		),
		companyId: loc.companyId
	}
}

function getTransportationItem(transportation) {
	return {
		id: transportation.plateNo,
		name: transportation.plateNo,
		company: transportation.company,
		companyId: transportation.companyId
	}
}

function getUserItem(user) {
	return {
		id: user.id,
		name: user.firstName + ' ' + user.lastName,
		companyId: user.companyId,
		type: user.type
	}
}

function getCompanyAccessItem(company) {
	return {
		id: company.id,
		name: company.name,
		description: company.description
	}
}


export const DropDownItemsUtil = {

	// individual option item retrieval
	getCompanyDisplay,
	getStorageLocationDisplay,
	getCompanyItem,
	getStorageLocationItem,
	getTransportationItem,
	getUserItem,
	getCompanyAccessItem,

	retrieveAssetConditions: (conditions) => {
		let conditionItems = [];

		// init value
		conditionItems.push({
			value: null,
			text: ' - Please select - ',
		});

		_.forEach(conditions, item => {
			if (item.isActive === true) {
				conditionItems.push({
					value: item.condition,
					text: item.condition,
				});
			}
		});

		// sort options by text
		conditionItems = SortUtil.sortByAttr('text', conditionItems);
		conditionItems = _.uniqBy(conditionItems, 'text');

		return conditionItems;
	},

	retrieveIndustries: (industries, hasOthers) => {
		let industryItems = [];

		// init value
		industryItems.push({
			value: null,
			text: ' - Please select - ',
		});

		_.forEach(industries, industry => {
			industryItems.push({
				value: industry.id,
				text: industry.name,
			});
		});

		// sort options by text
		industryItems = SortUtil.sortByAttr('text', industryItems);
		industryItems = _.uniqBy(industryItems, 'text');

		if (hasOthers) {
			// Others
			industryItems.push({
				value: 'Others',
				text: ' - Others - ',
			});
		}

		return industryItems;
	},

	retrieveCompanies: (companies) => {
		let companyItems = [{
			value: config.companyDefaultValue,
			text: ' - Please select - '
		}];

		_.forEach(companies, company => {
			companyItems.push({
				value: getCompanyItem(company),
				text: getCompanyDisplay(company)
			});
		});

		// sort options by text
		companyItems = SortUtil.sortByAttr('text', companyItems);
		companyItems = _.uniqBy(companyItems, 'text');

		return companyItems;
	},

	retrieveActiveCompanies: (companies) => {
		let companyItems = [{
			value: config.companyDefaultValue,
			text: ' - Please select - '
		}];

		_.forEach(companies, company => {
			if (company.isActive === 'true') {
				companyItems.push({
					value: getCompanyItem(company),
					text: getCompanyDisplay(company)
				});
			}
		});

		// sort options by text
		companyItems = SortUtil.sortByAttr('text', companyItems);
		companyItems = _.uniqBy(companyItems, 'text');

		return companyItems;
	},

	retrieveAllCompanyAccess: companies => {
		let companyItems = [];

		_.forEach(companies, company => {
			companyItems.push({
				value: {
					id: company.id,
					name: company.name,
					description: company.description
				},
				text: company.name
			});
		});

		// sort options by text
		companyItems = SortUtil.sortByAttr('text', companyItems);
		companyItems = _.uniqBy(companyItems, 'text');

		return companyItems;
	},

	retrieveAssetOwners: companies => {
		let companyItems = [];

		companyItems.push({
			value: config.companyDefaultValue,
			text: ' - Please select - '
		});

		_.forEach(companies, company => {
			if ((company.type === 'Asset Service Provider' ||
				company.type === 'Hybrid') && company.brand !== '') {
				companyItems.push({
					value: getCompanyItem(company),
					text: company.name
				});
			}
		});

		// sort options by text
		companyItems = SortUtil.sortByAttr('text', companyItems);
		companyItems = _.uniqBy(companyItems, 'text');

		return companyItems;
	},

	retrieveConnectedCompanies: (connections, currCompany) => {
		let companyItems = [];

		companyItems.push({
			value: config.companyDefaultValue,
			text: ' - Please select - '
		});

		// include the company the current user belongs to
		let currCompanyOption = {
			value: getCompanyItem(currCompany),
			text: getCompanyDisplay(currCompany)
		};
		companyItems.push(currCompanyOption);

		// include other connection companies
		_.forEach(connections, connection => {
			if (connection.companyId === currCompany.id
				&& connection.isActive === 'true') {
				let conCompanyOption = {
					value: getCompanyItem(connection.connectedCompany),
					text: getCompanyDisplay(connection.connectedCompany)
				};

				let companyNamesArr = _.map(companyItems, 'text');
				if (!_.includes(companyNamesArr, connection.connectedCompany.name)) {
					companyItems.push(conCompanyOption);
				}
			}
		});

		// sort options by text
		companyItems = SortUtil.sortByAttr('text', companyItems);
		companyItems = _.uniqBy(companyItems, 'text');

		return companyItems;
	},

	retrieveStorageLocations: storageLocations => {
		let storageLocationItems = [];

		storageLocationItems.push({
			value: config.storageLocationDefaultValue,
			text: ' - Please select - '
		});

		_.forEach(storageLocations, loc => {
			storageLocationItems.push({
				value: getStorageLocationItem(loc),
				text: getStorageLocationDisplay(loc)
			});
		});

		// sort options by text
		storageLocationItems = SortUtil.sortByAttr('text', storageLocationItems);
		storageLocationItems = _.uniqBy(storageLocationItems, 'text');

		return storageLocationItems;
	},

	retrieveActiveStorageLocations: storageLocations => {
		let storageLocationItems = [];

		storageLocationItems.push({
			value: config.storageLocationDefaultValue,
			text: ' - Please select - '
		});

		_.forEach(storageLocations, loc => {
			if (loc.isActive === 'true') {
				storageLocationItems.push({
					value: getStorageLocationItem(loc),
					text: loc.name
				});
			}
		});

		// sort options by text
		storageLocationItems = SortUtil.sortByAttr('text', storageLocationItems);
		storageLocationItems = _.uniqBy(storageLocationItems, 'text');

		return storageLocationItems;
	},

	retrieveStorageLocationsByConnection: (connections, storageLocations, companyId, connectedCompanyId) => {
		let storageLocationItems = [];

		storageLocationItems.push({
			value: config.storageLocationDefaultValue,
			text: ' - Please select - '
		});


		if (companyId === connectedCompanyId) {
			// retrieve from list of storage locations
			_.forEach(storageLocations, loc => {
				if (loc.companyId === companyId && loc.isActive === 'true') {
					storageLocationItems.push({
						value: getStorageLocationItem(loc),
						text: getStorageLocationDisplay(loc)
					});
				}
			});

		} else {
			// retrieve from list of connections
			_.forEach(connections, connection => {
				if (connection.companyId === companyId
					&& connection.connectedCompanyId === connectedCompanyId
					&& connection.isActive === 'true') {
					let storageLocationsArr = Object.values(connection.storageLocations);
					storageLocationsArr.forEach(loc => {
						if (loc.isIncluded === 'true') {
							storageLocationItems.push({
								value: {
									id: loc.id,
									name: loc.name,
									isActive: loc.isActive,
									geoaddress: LocationUtil.getGeoaddress(
										loc.geoaddress
									),
									companyId: connection.connectedCompanyId
								},
								text: getStorageLocationDisplay(loc)
							});
						}
					});
				}
			});
		}

		// sort options by text
		storageLocationItems = SortUtil.sortByAttr('text', storageLocationItems);
		storageLocationItems = _.uniqBy(storageLocationItems, 'text');

		return storageLocationItems;
	},

	retrieveStorageLocationsByCompany: (companyId, storageLocations) => {
		let storageLocationItems = [];

		storageLocationItems.push({
			value: config.storageLocationDefaultValue,
			text: ' - Please select - '
		});

		_.forEach(storageLocations, loc => {
			if (loc.companyId === companyId && loc.isActive === 'true') {
				storageLocationItems.push({
					value: getStorageLocationItem(loc),
					text: getStorageLocationDisplay(loc)
				});
			}
		});

		// sort options by text
		storageLocationItems = SortUtil.sortByAttr('text', storageLocationItems);
		storageLocationItems = _.uniqBy(storageLocationItems, 'text');

		return storageLocationItems;
	},

	retrieveStickerAssetTypes: (assetTypes) => {
		let assetTypeItems = [];
		assetTypeItems.push({
			value: null,
			text: ' - Please select - '
		});

		_.forEach(assetTypes, type => {
			if (type.assetTagging === 'Default' && type.isActive === 'true') {
				assetTypeItems.push({
					value: {
						id: type.id,
						name: type.name,
						brand: type.brand,
						uom: type.uom,
						unitCost: type.unitCost,
						noOfStickers: typeof type.noOfStickers === 'undefined' ? 1 : type.noOfStickers,
					},
					text: type.name
				});
			}
		});

		// sort options by text
		assetTypeItems = SortUtil.sortByAttr('text', assetTypeItems);
		assetTypeItems = _.uniqBy(assetTypeItems, 'text');

		return assetTypeItems;
	},

	retrieveAssetTypes: (assetTypes, activeOnly = false) => {
		let assetTypeItems = [];
		assetTypeItems.push({
			value: config.assetTypeDefaultValue,
			text: ' - Please select - '
		});

		_.forEach(assetTypes, type => {
			assetTypeItems.push({
				value: {
					id: type.id,
					name: type.name,
					origin: type.origin,
					originId: type.originId
				},
				text: type.name
			});
		});

		// include active asset types only
		if (activeOnly) {
			assetTypeItems = _.filter(assetTypeItems, o => {
				let assetType = assetTypes[o.value.id];
				return !assetType || (assetType.isActive === 'true');
			});
		}

		// sort options by text
		assetTypeItems = SortUtil.sortByAttr('text', assetTypeItems);
		assetTypeItems = _.uniqBy(assetTypeItems, 'text');

		return assetTypeItems;
	},

	retrieveAssetTypeCodes: assetTypeCodes => {
		let assetTypeCodeItems = [];

		assetTypeCodeItems.push({
			value: null,
			text: ' - Please select - '
		});

		_.forEach(assetTypeCodes, code => {
			assetTypeCodeItems.push({
				value: {
					id: code.id,
					codeName: code.codeName,
					prefix: code.prefix
				},
				text: code.codeName
			});
		});

		// sort options by text
		assetTypeCodeItems = SortUtil.sortByAttr('text', assetTypeCodeItems);
		assetTypeCodeItems = _.uniqBy(assetTypeCodeItems, 'text');

		return assetTypeCodeItems;
	},

	retrieveActiveUsers: users => {
		let userItems = [];

		userItems.push({
			value: config.userDefaultValue,
			text: ' - Please select - '
		});

		_.forEach(users, user => {
			if (user.isActive === 'true') {
				let name = user.firstName + ' ' + user.lastName;
				userItems.push({
					value: {
						id: user.id,
						name: name,
						companyId: user.companyId,
						type: user.type
					},
					text: name
				});
			}
		});

		// sort options by text
		userItems = SortUtil.sortByAttr('text', userItems);
		userItems = _.uniqBy(userItems, 'text');

		return userItems;
	},

	retrieveTransportations: (transportations, hasOthers = true) => {
		let transportationItems = [];

		transportationItems.push({
			value: config.transportationDefaultValue,
			text: ' - Please select - '
		});

		if (hasOthers) {
			transportationItems.push({
				value: config.transportationOthersValue,
				text: ' - Others - '
			});
		}

		_.forEach(transportations, transportation => {
			if (transportation.isActive === 'true') {
				transportationItems.push({
					value: {
						id: transportation.plateNo,
						name: transportation.plateNo,
						company: transportation.company,
						companyId: transportation.companyId
					},
					text: transportation.plateNo
				});
			}
		});

		// sort options by text
		transportationItems = SortUtil.sortByAttr('text', transportationItems);
		transportationItems = _.uniqBy(transportationItems, 'text');

		return transportationItems;
	},

	retrievePlateNos: transportations => {
		let plateNoItems = [];

		plateNoItems.push({
			value: '',
			text: ' - Please select - '
		});

		_.forEach(transportations, transportation => {
			if (transportation.isActive === 'true') {
				plateNoItems.push({
					value: transportation.plateNo,
					text: transportation.plateNo
				});
			}
		});

		// sort options by text
		plateNoItems = SortUtil.sortByAttr('text', plateNoItems);
		plateNoItems = _.uniqBy(plateNoItems, 'text');

		return plateNoItems;
	},

	retriveAssetTaggingTypes: () => {
		return [
			{
				value: '',
				text: ' - Please select - '
			},
			{ text: 'Default', value: "Default" },
			{ text: 'Custom', value: "Custom" },
		];
	},

	retrieveAssetCategories: (categories, hasOthers) => {
		let assetCategoryItems = [];

		assetCategoryItems.push({
			value: null,
			text: ' - Please select - ',
		});

		_.forEach(categories, category => {
			assetCategoryItems.push({
				value: category.name,
				text: category.name,
			});
		});

		// sort options by text
		assetCategoryItems = SortUtil.sortByAttr('text', assetCategoryItems);
		assetCategoryItems = _.uniqBy(assetCategoryItems, 'text');


		if (hasOthers) {
			// Others
			assetCategoryItems.push({
				value: 'Others',
				text: ' - Others - ',
			});
		}

		return assetCategoryItems;
	},

	retrieveUOMs: (uoms) => {
		let uomItems = [];

		uomItems.push({
			value: null,
			text: ' - Please select - '
		});

		_.forEach(uoms, uom => {
			uomItems.push({
				value: uom.id,
				text: uom.code + ' (' + uom.name + ')',
			});
		});

		// sort options by text
		uomItems = SortUtil.sortByAttr('text', uomItems);
		uomItems = _.uniqBy(uomItems, 'text');

		return uomItems;
	},

	retrieveConnectionTypes: (connectionTypes) => {
		let items = [];

		_.forEach(connectionTypes, connectionType => {
			items.push({
				value: connectionType.name,
				text: connectionType.name,
			});
		});

		// sort options by text
		items = SortUtil.sortByAttr('text', items);
		items = _.uniqBy(items, 'text');

		items.push({
			text: "Others",
			value: "Others"
		});

		return items;
	},

	retrieveYearOptions: () => {
		let items = [{
			text: ' - Please select - ',
			value: null
		}];

		const currentYear = moment().tz('Asia/Manila').format('YYYY');

		for (let i = 0; i < 10; i++) {
			items.push({
				text: currentYear - i,
				value: currentYear - i
			});
		}

		return items;
	},

	retrieveMonthOptions: () => {
		return [
			{
				text: ' - Please select - ',
				value: null
			},
			{
				text: 'January',
				value: 1,
			},
			{
				text: 'February',
				value: 2,
			},
			{
				text: 'March',
				value: 3,
			},
			{
				text: 'April',
				value: 4,
			},
			{
				text: 'May',
				value: 5,
			},
			{
				text: 'June',
				value: 6,
			},
			{
				text: 'July',
				value: 7,
			},
			{
				text: 'August',
				value: 8,
			},
			{
				text: 'September',
				value: 9,
			},
			{
				text: 'October',
				value: 10,
			},
			{
				text: 'November',
				value: 11,
			},
			{
				text: 'December',
				value: 12,
			}
		];
	},

	retrieveItems: (options) => {
		let items = [];

		items.push({
			value: null,
			text: ' - Please select - '
		});

		_.forEach(options, option => {
			items.push({
				value: option,
				text: option,
			});
		});

		// sort options by text
		items = SortUtil.sortByAttr('text', items);
		items = _.uniqBy(items, 'text');

		return items;
	},

};
